<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left" label-width="108px">
          <ayl-main title="基本信息" :isCollapse="false">
            <div slot="main" class="p24px">
              <el-form-item label="所属公司" prop="deviceInfoVOIOV.company">
                <el-select v-model="form.deviceInfoVOIOV.company"
                           :disabled="$route.query.type==='edit'||$route.query.type==='detail'"
                           placeholder="请选择"
                           @change="checkCompany"
                           clearable>
                  <el-option v-for="(item, idx) in companyList"
                             :key="idx"
                             :label="item.company"
                             :value="item.companyId"/>
                </el-select>
              </el-form-item>
              <el-form-item label="设备号" prop="deviceInfoVOIOV.deviceName">
                <el-input v-model="form.deviceInfoVOIOV.deviceName"
                          :disabled="$route.query.type==='detail'"
                          maxlength="12"></el-input>
              </el-form-item>
              <el-form-item label="SIM卡号" prop="deviceInfoVOIOV.simCardNumber">
                <el-select v-model="form.deviceInfoVOIOV.simCardNumber"
                           :disabled="$route.query.type==='detail'"
                           placeholder="请选择" clearable>
                  <el-option v-for="(item, idx) in simCardNumberList"
                             :key="idx"
                             :label="item"
                             :value="item"/>
                </el-select>
              </el-form-item>
              <el-form-item label="设备厂家" prop="deviceInfoVOIOV.deviceCompany">
                <el-select v-model="form.deviceInfoVOIOV.deviceCompany"
                           :disabled="$route.query.type==='detail'"
                           placeholder="请选择" clearable>
                  <el-option v-for="(item, idx) in $enums.deviceCompanyType.list"
                             :key="idx"
                             :label="item.label"
                             :value="item.value"/>
                </el-select>
                <!-- <el-input v-model="form.deviceInfoVOIOV.deviceCompany"
                          :disabled="$route.query.type==='detail'"
                          maxlength="64"></el-input> -->
              </el-form-item>
              <el-form-item label="产品型号" prop="deviceInfoVOIOV.deviceModel">
                <el-input v-model="form.deviceInfoVOIOV.deviceModel"
                          :disabled="$route.query.type==='detail'"
                          maxlength="64"></el-input>
              </el-form-item>
              <el-form-item label="设备类型" prop="deviceInfoVOIOV.deviceType">
                <el-select v-model="form.deviceInfoVOIOV.deviceType"
                           :disabled="$route.query.type==='detail'"
                           placeholder="请选择" clearable>
                  <el-option v-for="(item, idx) in $enums.Operator.list"
                             :key="idx"
                             :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="协议类型">
                <el-select v-model="form.deviceInfoVOIOV.protocolType"
                           multiple
                           collapse-tags
                           :disabled="$route.query.type==='detail'"
                           placeholder="请选择" clearable>
                  <el-option v-for="(item, idx) in $enums.ProtocolType.list"
                             :key="idx"
                             :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>

              <el-form-item label="设备功能">
                <el-select v-model="form.deviceInfoVOIOV.deviceFunction"
                           multiple
                           collapse-tags
                           :disabled="$route.query.type==='detail'"
                           placeholder="请选择" clearable>
                  <el-option v-for="(item, idx) in $enums.equipmentFunction.list"
                             :key="idx"
                             :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>

              <div v-if="form.deviceInfoVOIOV.deviceType==='视频终端'">
                <span class="form-line"></span>
                <el-form-item label="摄像头类型" class="clearMr">
                  <el-select v-model="pipeNumber"
                             multiple
                             collapse-tags
                             style="width: 210px;"
                             :disabled="$route.query.type==='detail'"
                             @change="cameraItem"
                             placeholder="请选择" clearable>
                    <el-option v-for="(item, idx) in $enums.cameraType.list"
                               :key="idx"
                               :label="item.label"
                               :value="item.label"/>
                  </el-select>
                  <!-- <el-input v-model="form.deviceInfoVOIOV.pipeNumber"
                            :disabled="$route.query.type==='detail'"
                            @keyup.enter.native="addAisle"
                            maxlength="2"></el-input> -->
                </el-form-item>
                <!-- <span @click="addAisle"
                      v-if="$route.query.type!=='detail'"
                      style="color: #0575E6; line-height: 32px"
                      class="curp">确认</span> -->
                <div class="aisle"
                     v-if="form.deviceInfoVOIOV.deviceType==='视频终端'"
                     v-loading="aisleLoading"
                     element-loading-text='摄像头配置正在生成...'>
                  <div v-for="(item, idx) in form.deviceAttachedInfoVOIOVs" :key="idx">
                    <div class="aisle-form">
                      <span class="aisle-title">{{item.pipeName}}</span>
                      <!--<el-form-item label="名称"-->
                      <!--:prop="'deviceAttachedInfoVOIOVs['+idx+'].pipeName'"-->
                      <!--:rules="[{max: 16, required: true, message: '请正确输入2-16位的通道名称', trigger: 'blur'}]">-->
                      <!--&lt;!&ndash;, {validator: $validator.isContainBlank}&ndash;&gt;-->
                      <!--<el-input v-model="item.pipeName" maxlength="16"-->
                      <!--:disabled="$route.query.type==='detail'"></el-input>-->
                      <!--</el-form-item>-->
                      <el-form-item label="设备类型"
                                    :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedType'"
                                    :rules="{required: true, message: '请选择设备类型', trigger: 'change'}">
                        <el-select v-model="item.attachedType" placeholder="请选择"
                                   :disabled="$route.query.type==='detail'">
                          <el-option v-for="(item, idx) in $enums.DeviceType.list"
                                     :key="idx"
                                     :label="item.label"
                                     :value="item.value"/>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="使用状态"
                                    :prop="'deviceAttachedInfoVOIOVs['+idx+'].status'"
                                    :rules="{required: true, message: '请选择使用状态', trigger: 'change'}">
                        <el-select v-model="item.status" placeholder="请选择"
                                   :disabled="$route.query.type==='detail'">
                          <el-option v-for="(item, idx) in $enums.UseType.list"
                                     :key="idx"
                                     :label="item.label"
                                     :value="item.value"/>
                        </el-select>
                      </el-form-item>
                      <!--<el-form-item label="物理通道"-->
                      <!--:prop="'deviceAttachedInfoVOIOVs['+idx+'].physicalPipe'"-->
                      <!--:rules="{required: true, message: '请选择物理通道', trigger: 'change'}">-->
                      <!--<el-select v-model="item.physicalPipe"-->
                      <!--:disabled="$route.query.type==='detail'" placeholder="请选择" clearable>-->
                      <!--<el-option v-for="(item, idx) in 10"-->
                      <!--:key="idx"-->
                      <!--:label="item"-->
                      <!--:value="item"/>-->
                      <!--</el-select>-->
                      <!--</el-form-item>-->
                      <el-form-item label="型号"
                                    :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedModel'"
                                    :rules="{trigger: 'blur'}">
                        <!--validator: $validator.isContainBlank-->
                        <el-input v-model="item.attachedModel"
                                  :disabled="$route.query.type==='detail'" maxlength="16"></el-input>
                      </el-form-item>

                      <el-form-item label="生产厂家"
                                    :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedCompany'"
                                    :rules="{trigger: 'blur'}">
                        <!--validator: $validator.isContainBlank-->
                        <el-input v-model="item.attachedCompany"
                                  :disabled="$route.query.type==='detail'"></el-input>
                      </el-form-item>

                      <el-form-item label="安装位置"
                                    :prop="'deviceAttachedInfoVOIOVs['+idx+'].installLocation'"
                                    :rules="{trigger: 'blur',}">
                        <!--validator: $validator.isContainBlank-->
                        <el-input v-model="item.installLocation"
                                  :disabled="$route.query.type==='detail'"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </ayl-main>
          <ayl-main title="其他信息(选填)" :isCollapse="false">
            <div slot="main" class="p24px">
              <el-form-item label="主服务器地址" prop="deviceInfoVOIOV.serverAddress">
                <el-input v-model="form.deviceInfoVOIOV.serverAddress"
                          :disabled="$route.query.type==='detail'" maxlength="32"></el-input>
              </el-form-item>
              <el-form-item label="主服务器端口" prop="deviceInfoVOIOV.serverPort">
                <el-input v-model="form.deviceInfoVOIOV.serverPort"
                          :disabled="$route.query.type==='detail'" maxlength="4"></el-input>
              </el-form-item>
              <el-form-item label="备服务器地址" prop="deviceInfoVOIOV.alternateServerAddress">
                <el-input v-model="form.deviceInfoVOIOV.alternateServerAddress"
                          :disabled="$route.query.type==='detail'" maxlength="32"></el-input>
              </el-form-item>
              <el-form-item label="备服务器端口" prop="deviceInfoVOIOV.alternateServerPort">
                <el-input v-model="form.deviceInfoVOIOV.alternateServerPort"
                          :disabled="$route.query.type==='detail'" maxlength="4"></el-input>
              </el-form-item>
              <el-form-item label="音频编码格式">
                <el-select v-model="form.deviceInfoVOIOV.audioEncodeFormat"
                           :disabled="$route.query.type==='detail'" placeholder="请选择" clearable>
                  <el-option v-for="(item, idx) in $enums.AudioEncode.list"
                             :key="idx"
                             :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>
            </div>
          </ayl-main>
        </el-form>
        <div class="tac mt40px">
          <el-button type="primary" @click="submitCar"
                     :loading="submitLoading"
                     v-if="$route.query.type!=='detail'">确 认
          </el-button>
          <el-button style="margin-left: 30px" @click="$router.push('/vehicle-management/equipment-management')">返 回
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const aisle = {
    pipeName: null,
    attachedType: null,
    status: null,
    physicalPipe: null,
    attachedModel: null,
    installLocation: null,
    attachedCompany: null,
  }

  export default {
    data () {
      const validateDeviceName = (rule, value, callback) => {
        if (!value) return callback(new Error('请正确输入12位的设备号'))
        if (value.length !== 12 || !(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请正确输入12位的设备号'))
        } else {
          callback()
        }
      }
      const validatePipeNumber = (rule, value, callback) => {
        if (value < 1 || value > 16 || !value) return callback(new Error('请正确输入1-16的整数'))
        if (!(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请正确输入1-16的整数'))
        } else {
          callback()
        }
      }
      return {
        nav: [
          {name: '车辆管理', path: ''},
          {name: '设备管理', path: '/vehicle-management/equipment-management'},
          {name: '新增', path: ''},
        ],
        form: {
          deviceInfoVOIOV: {
            company: null,
            deviceType: null,
            deviceName: null,
            simCardNumber: null,
            deviceModel: null,
            deviceCompany: null,
            audioEncodeFormat: null,
            netProtocol: 'TCP',
            deviceFunction: [],
            serverAddress: null,
            serverPort: null,
            alternateServerAddress: null,
            alternateServerPort: null,
            position: null,
            remark: null,
            protocolType: [],
            pipeNumber: 0
          },
          deviceAttachedInfoVOIOVs: []
        },
        rules: {
          'deviceInfoVOIOV.company': {required: true, message: '请选择所属公司', trigger: 'change'},
          'deviceInfoVOIOV.deviceType': {required: true, message: '请选择设备类型', trigger: 'change'},
          // "deviceInfoVOIOV.netProtocol": {required: true, message: '请选择协议类型', trigger: 'change'},
          'deviceInfoVOIOV.simCardNumber': {required: true, message: '请选择SIM卡号', trigger: 'change'},
          'deviceInfoVOIOV.deviceName': {required: true, validator: validateDeviceName, trigger: 'blur'},
          // "deviceInfoVOIOV.deviceName": { min: 12, max: 12, required: true, message: '请正确输入12位的设备号', trigger: 'blur'},
          'deviceInfoVOIOV.deviceCompany': [{required: true, message: '请选择设备厂家', trigger: 'change'},
            // {validator: this.$validator.isContainBlank}
          ],
          'deviceInfoVOIOV.deviceModel': [{max: 64, required: true, message: '请输入产品型号', trigger: 'blur'},
            // {validator: this.$validator.isContainBlank}
          ],
          'deviceInfoVOIOV.pipeNumber': {required: true, trigger: 'blur', validator: validatePipeNumber},
          'deviceInfoVOIOV.serverAddress': {trigger: 'blur',},
          // validator: this.$validator.isContainBlank
          'deviceInfoVOIOV.serverPort': {trigger: 'blur',},
          // validator: this.$validator.isContainBlank
          'deviceInfoVOIOV.alternateServerAddress': {trigger: 'blur',},
          // validator: this.$validator.isContainBlank
          'deviceInfoVOIOV.alternateServerPort': {trigger: 'blur',},
          // validator: this.$validator.isContainBlank
        },
        submitLoading: false,
        aisleShow: true,
        aisleLoading: false,
        companyList: [],
        simCardNumberList: [],
        num: 0,
        type: null,
        pipeNumber: [],
        val_: []
      }
    },
    watch: {
      'pipeNumber': {
        handler (newName, oldName) {
          this.val_ = this.$_.differenceWith(oldName, newName, this.$_.isEqual)

          setTimeout(() => {
            oldName.forEach((e, i) => {
              if (this.val_[0] === e) {
                this.num = i
              }
            })
          }, 50)
        },
      },
    },
    methods: {
      //获取摄像头信息
      cameraItem (val) {
        if (val.length === 0) {
          this.form.deviceAttachedInfoVOIOVs = []
          return
        }
        if (val.length > this.form.deviceAttachedInfoVOIOVs.length) {
          for (let i = 0; i < val.length - this.form.deviceAttachedInfoVOIOVs.length; i++) {
            aisle.pipeName = val[this.form.deviceAttachedInfoVOIOVs.length]
            this.form.deviceAttachedInfoVOIOVs.push(this.$_.cloneDeep(aisle))
          }
        } else {
          setTimeout(() => {
            this.form.deviceAttachedInfoVOIOVs.splice(this.num, 1)
          }, 250)
        }
      },
      async checkCompany (val) {
        if (!val) {
          this.simCardNumberList = []
          this.form.deviceInfoVOIOV.simCardNumber = null
          return
        }
        const tenantIdData = this.companyList.filter(e => e.companyId === this.form.deviceInfoVOIOV.company)
        this.simCardNumberList = await this.$api.GetNotEnableSimNumber({
          tenantId: tenantIdData[0].tenantId
        })
        this.form.deviceInfoVOIOV.simCardNumber = this.simCardNumberList[0] ?
          this.simCardNumberList[0] :
          null
      },
      // addAisle () {
      //   // if (Number(this.form.deviceInfoVOIOV.pipeNumber) < 1 || Number(this.form.deviceInfoVOIOV.pipeNumber) > 16 || !(/^[0-9]*[1-9][0-9]*$/.test(this.form.deviceInfoVOIOV.pipeNumber))) {
      //   //   this.$message.warning('通道数目必须为1-16的正整数')
      //   //   return
      //   // }
      //   this.aisleShow = true
      //   this.aisleLoading = true
      //   setTimeout(() => {
      //     this.aisleLoading = false
      //     if (Number(this.form.deviceInfoVOIOV.pipeNumber) < Number(this.form.deviceAttachedInfoVOIOVs.length)) {
      //       this.form.deviceAttachedInfoVOIOVs =
      //         this.form.deviceAttachedInfoVOIOVs.slice(0, Number(this.form.deviceInfoVOIOV.pipeNumber))
      //     } else {
      //       this.num =
      //         Number(this.form.deviceInfoVOIOV.pipeNumber) -
      //         Number(this.form.deviceAttachedInfoVOIOVs.length)
      //       for (let i = 0; i < this.num; i++) {
      //         this.form.deviceAttachedInfoVOIOVs.push(this.$_.cloneDeep(aisle))
      //       }
      //     }
      //   }, 350)
      // },
      submitCar () {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.form.deviceAttachedInfoVOIOVs.forEach(e => {
              e.physicalPipe = e.pipeName.slice(2, 3)
            })
            this.form.deviceInfoVOIOV.pipeNumber = String(this.form.deviceAttachedInfoVOIOVs.length)

            let message = '新增'
            for (let item of this.companyList) {
              if(this.form.deviceInfoVOIOV.company==item.company){
                this.form.deviceInfoVOIOV.company = item.companyId
              }
            }
            if (this.$route.query.type === 'edit') {
              await this.$api.UpdateDeviceInfo({
                tenantId: this.form.deviceInfoVOIOV.tenantId,
                deviceVOIOV: this.form
              })
              message = '编辑'
            } else {
              await this.$api.InsertDeviceInfo({
                deviceVOIOV: this.form
              })
            }
            this.$notify({
              title: '成功',
              message: message + '设备信息成功',
              type: 'success'
            })
            this.$router.push('/vehicle-management/equipment-management')
          } catch (e) {
            //console.log(e)
          }
          this.submitLoading = false
        })
      }
    },
    async mounted () {
      this.companyList = await this.$api.SearchCompanyList({})
      if (!this.$route.query.type) return
      this.$route.query.type === 'edit' ?
        this.nav[this.nav.length - 1].name = '编辑' :
        this.nav[this.nav.length - 1].name = '查看设备详情'

      this.form = await this.$api.GetDeviceInfo({
        deviceId: this.$route.query.deviceId
      })
      this.pipeNumber = this.form.deviceAttachedInfoVOIOVs.map(e => e.pipeName)
      const tenantIdData = this.companyList.filter(e => e.company === this.form.deviceInfoVOIOV.company)
      this.simCardNumberList = await this.$api.GetNotEnableSimNumber({
        tenantId: tenantIdData[0].tenantId
      })
    }
  }
</script>

<style lang='sass' scoped>
  .clearBox
    .form-line
      display: block
      margin-bottom: 16px
      width: 100%
      height: 1px
      background: rgba(232, 232, 232, 1)

    .clearMr
      margin-right: 14px !important

    .aisle
      background-color: $lightGrey
      padding: 24px

      &-title
        display: block
        color: #000
        margin-bottom: 10px

      &-form
        padding: 12px
        display: block
        background-color: #fff
        border: 1px solid rgba(217, 217, 217, 1)
        margin-bottom: 14px

        /deep/ .el-form-item
          margin-right: 30px !important

        /deep/ .el-form-item__label
          width: 85px !important

        /deep/ .el-select, /deep/ .el-input
          width: 200px !important
</style>
